import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import React from 'react';
import RightNavBar from '../../components/docs/RightNavBar/index';
import { Box, styled } from '@mui/system';
import SEO from '../../components/common/Seo';
import theme, { BREAKPOINTS, MOBILE_SPACING } from '../../utils/theme';
import Markdoc from '@markdoc/markdoc';
import { customConfig, components } from '../../schema/config';
import {readFileSync, readdirSync, statSync} from 'fs';
import { getPrivatePage } from '../../components/common/SecurityWrapper';
import { appendRouteToUrl, getBaseSettings } from '../../utils/helper';
import { sortSDKNavItems } from '../../utils/helpers';
import { replaceRoutes } from '../../utils/mapRoutes';

const useStyles = {
  outerBox: {
    display: 'flex',
    flex: '1 1 0%',
    width: '100%',
  },
  innerBox: {
    display: 'flex',
  },
  centerBoxWithRightNav: {
    width: '699px',
    [BREAKPOINTS.MOBILE]: {
      width: '100%',
    },
    [BREAKPOINTS.DESKTOP]: {
      width: 'calc(100% - 410px)',
      paddingLeft: theme.spacing(10),
    },
  },
  centerBoxWithNoRightNav: {
    width: '699px',
    [BREAKPOINTS.MOBILE]: {
      width: '100%',
    },
    [BREAKPOINTS.DESKTOP]: {
      maxWidth: '410px',
      margin: 'auto',
    },
  },
};

const ContentBox = styled('div')(({ theme }) => ({
  width: '699px',
  [BREAKPOINTS.MOBILE]: {
    width: '100%',
  },
  [BREAKPOINTS.DESKTOP]: {
    width: 'calc(100% - 410px)',
    paddingLeft: theme.spacing(10),
  },
}));

const InnerBox = styled('div')(({ theme }) => ({
  maxWidth: '989px',
  margin: '44px auto',
  marginBottom: '0px',
  [BREAKPOINTS.MOBILE]: {
    maxWidth: `calc( 100% - ${MOBILE_SPACING.MARGIN} )`,
    margin: 'auto 20px',
    marginTop: theme.spacing(3),
  },
  [BREAKPOINTS.DESKTOP]: {
    width: 'calc(100% - 88px)',
  },
}));

const SideBar = styled('div')(({ theme }) => ({
  width: '290px',
  [BREAKPOINTS.MOBILE]: {
    display: 'none',
  },
}));

const RightBar = styled('div')(({ theme }) => ({
  display: 'flex',
  position: 'fixed',
  marginLeft: '40px',
  width: '290px',
  maxHeight:'calc(100% - 150px)',
  overflow:'scroll',
  [BREAKPOINTS.MOBILE]: {
    display: 'none',
  },
}));

const buildSEO = (frontmatter, excerpt, headings) => {
  let title = '';
  let description = '';
  let docType='';
  if (frontmatter && frontmatter.title) {
    title = frontmatter.title;
  } else if (headings) {
    const headerList = headings.filter((header) => header.depth === 1);
    title = headerList && headerList[0] ? headerList[0].value : '';
  } else {
    title = '';
  }

  if (frontmatter && frontmatter.description) {
    description = frontmatter.description;
  } else {
    description = excerpt;
  }

  if(frontmatter && frontmatter.docType){
    docType=frontmatter.docType;
  }
  else{
    docType='';
  }

  return { title: title, description: description, docType: docType };
};

const putRightNavItems = (tableOfContents, rightNavItems) => {
  if (tableOfContents.items) {
    const level1 = tableOfContents.items[0];
    if (level1.items) {
      for (const level2 of level1.items) {
        rightNavItems.push({ url: level2.url, value: level2.title, depth: 2 });
        if (level2.items) {
          for (const level3 of level2.items) {
            rightNavItems.push({
              url: level3.url,
              value: level3.title,
              depth: 3,
            });
          }
        }
      }
    }
  }
};

function generateHeadings(content, headings, rightNavItems) {
  content.children.forEach(item => {
    if(item.name === 'H2' || item.name === 'H3')
    {
      rightNavItems.push({ url: '#'+item.attributes.id, value: item.children[0], depth: item.attributes.level });
    }
    else if(item.name === 'H1')
    {
      headings.push({ url: '#'+item.attributes.id, value: item.children[0], depth: item.attributes.level });
    }
    else if(item.name && item.name !== 'Tabs' && item.children.length > 1)
    {
      generateHeadings(item, headings, rightNavItems);
    }
  });
}


function mdoc(mdocData, serverData) {
  const {fields,frontmatter} = mdocData.markdoc;
  const {excerpt} = mdocData.markdownRemark;
  const config = {
    ...customConfig,
    variables: {
      markdoc:{
        frontmatter: {
          title: frontmatter.title
        }
      },
    }
  };

  let slug='';
  if(fields && fields.slug){
   slug=fields.slug;
  }
  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      let hash = window.location.hash;
      if (hash && hash.charAt(hash.length - 1) === '/') {
        hash = hash.substr(0, hash.length - 1);
      }
      appendRouteToUrl(hash);
    }
  }, [window.location.hash]);

  const rightNavItems = [] as any;
  const headings = [] as any;

  const ast = Markdoc.parse(serverData.pageData);
  const content = Markdoc.transform(ast, config);
  generateHeadings(content, headings, rightNavItems);

  return (
    <>
      <SEO {...buildSEO(frontmatter, excerpt, headings)} />
      <Box sx={useStyles.outerBox}>
        <InnerBox>
          <Box sx={useStyles.innerBox}>
            <Box
              sx={
                rightNavItems.length > 0
                  ? useStyles.centerBoxWithRightNav
                  : useStyles.centerBoxWithNoRightNav
              }
            >
              <Box className="mdoc-templete">
                {Markdoc.renderers.react(content, React, {
                  components: {
                    ...components,
                  }
                })}
              </Box>
            </Box>
            {rightNavItems.length > 0 && 
              <SideBar>
                <Box>
                  <RightBar>
                    <Box>
                      <RightNavBar rightNavItems={rightNavItems} pathName={slug}/>
                    </Box>
                  </RightBar>
                </Box>
              </SideBar>
            }
          </Box>
        </InnerBox>
      </Box>
    </>
  );
}

function index(mdxData) {
  const { body, headings, frontmatter, excerpt, tableOfContents,fields} = mdxData.mdx;
  let slug='';
  if(fields && fields.slug){
   slug=fields.slug;
  }
  const rightNavItems = [] as any;
  putRightNavItems(tableOfContents, rightNavItems);
  return (
    <>
      <SEO {...buildSEO(frontmatter, excerpt, headings)} />
      <Box sx={useStyles.outerBox}>
        <InnerBox>
          <Box sx={useStyles.innerBox}>
            <Box
              sx={
                rightNavItems.length > 0
                  ? useStyles.centerBoxWithRightNav
                  : useStyles.centerBoxWithNoRightNav
              }
            >
              <Box className="mdx-templete">
                <MDXRenderer>{body}</MDXRenderer>
              </Box>
            </Box>
            {rightNavItems.length > 0 && 
              <SideBar>
                <Box>
                  <RightBar>
                    <Box>
                      <RightNavBar rightNavItems={rightNavItems} pathName={slug}/>
                    </Box>
                  </RightBar>
                </Box>
              </SideBar>
            }
          </Box>
        </InnerBox>
      </Box>
    </>
  );
}

export default React.memo(({ data, serverData }:any) => {

  if(data && data.mdx)
  {
    return index(data);
  }
  else if(data && data.markdoc)
  {
    if(serverData.isPrivate)
    {
      return getPrivatePage(serverData.pageData);
    }
    else {
      return mdoc(data, serverData);
    }
  }
  return <></>;
});

export const query = graphql`
  query PageBySlug($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      body
      fields {
        slug
      }
      headings {
        value
        depth
      }
      tableOfContents(maxDepth: 3)
      frontmatter {
        title
      }
      excerpt(pruneLength: 250, truncate: false)
    }
    markdoc(fields: {slug: {eq: $slug}}) {
      fields {
        slug
      }
      frontmatter {
        title
        docType
      }
    }
    markdownRemark(fields: {slug: {eq: $slug}}) {
      excerpt(pruneLength: 250, truncate: false)
    }
  }
`;

export async function getServerData(context) {

const sideNav = readFileSync(`./src/utils/sideNavItems.json`, 'utf8');
const sideNavItem = JSON.parse(sideNav);

const route = readFileSync(`./src/docs-config/src/constants/routes.json`, 'utf8');
const routes = JSON.parse(route);

const sideNavItems = replaceRoutes(sideNavItem, routes);

const path = require('path');

  const slug = context.pageContext.slug;
  let file;
  let title;
  let sdkSideNavList;
  let type = 'docs';
  const pathArray = slug.slice(1,-1).split('/');

  const removeTrailingslash = (url) => {
    if (url && url.charAt(url.length - 1) === '/') {
      return url.substr(0, url.length - 1);
    }
    return url;
  };

  const setTitle = (element) => {
    if(element.public){
      return true;
    }
    title = element.label;
    return false;
  };

  const isPublicRouteInArticlePages = (currentRoute, sideNavItems) => {
    if(context && context.headers)
    {
      const isLocalhost = context.headers.get('host');
      if (isLocalhost.startsWith('localhost')) {
        return true;
      }
    }

    if (sideNavItems) {
      const res = sideNavItems.filter((element) =>
        removeTrailingslash(element.route) === removeTrailingslash(currentRoute) || currentRoute?.includes('/content/docs') && currentRoute?.includes(element.route)
          ? setTitle(element)
          : isPublicRouteInArticlePages(currentRoute, element.child),
      );
      return res.length > 0 ? true : false;
    }
    return false;
  };

  function readFolderStructure(folderPath, relativePath) {
    const folderContents = readdirSync(folderPath);
    const list:any = [];
    folderContents.forEach((item) => {
      const folderObject:any = {};
      const itemPath = path.join(folderPath, item);
      const isDirectory = statSync(itemPath).isDirectory();

      folderObject.label = item.charAt(0).toUpperCase() + item.slice(1);
      if (isDirectory) {
        folderObject.child = readFolderStructure(itemPath, relativePath + item + '/');
      } else {
        const fileName = path.parse(item).name; 
        folderObject.label = fileName.charAt(0).toUpperCase() + fileName.slice(1);
        folderObject.route = relativePath + fileName + '/';
      }

      list.push(folderObject);
    });
    return list;
  }

  let cookies:any = [];
  if(context.headers.get('cookie'))
  {
    cookies = context.headers.get('cookie').split(';');
  }
  let sessionId = '';
  for (const element of cookies) {
    if (element.trim().match('^session-id=')) {
      sessionId = element.replace('session-id=', '').trim();
    }
  }

  const session = async() => {
    return fetch(`${process.env.GATSBY_OKTA_DOMAIN_URL}/api/v1/sessions/${sessionId}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `SSWS ${process.env.GATSBY_OKTA_API_KEY}`
      }
    })
    .then((response) => response.json())
    .then((data) => {
      if(data && data.status === 'ACTIVE')
      {
        return true;
      }
      else{
        return false;
      }
    })
    .catch((error) => {
      return false;
    });
  };

  let isAuthenticated = false;
  if (sessionId && await session()) {
    isAuthenticated = true;
  }
  const baseSettings = await getBaseSettings(isAuthenticated);

  let isPrivate = false;
  if(isPublicRouteInArticlePages(slug, sideNavItems))
  {
    file = readFileSync(`./src/pages/content/docs/${slug.slice(1,-1)}.md`, 'utf8');
  }
  else{
    if(sessionId && await session())
    {
      file = readFileSync(`./src/pages/content/docs/${slug.slice(1,-1)}.md`, 'utf8');
    }
    else {
      if(pathArray[0] === 'sdks') {
        file = title || pathArray.slice(-1);
      }
      else {
        file = title || slug.slice(1,-1).replace(/-/g, ' ').replace(/\b\w/g, c => c.toUpperCase());
      }
      isPrivate = true;
    }
  }

  if(pathArray[0] === 'sdks') {
    type = 'sdk-ref';
    const relativePath = pathArray.slice(0, 2).join('/');
    const folderPath = './src/pages/content/docs/' + relativePath;

    const pathData = readFolderStructure(folderPath, `/${relativePath}/`);
    const sortedPathData = sortSDKNavItems(pathData, pathArray[1]);
    sdkSideNavList = [{label: 'Home', route: '/'}, ...sortedPathData];
  }

  return {
    props: {
      pageData: file,
      isPrivate: isPrivate,
      sdkSideNavList: sdkSideNavList || [],
      docsSideNavList: sideNavItems,
      type: type,
      baseSettings: baseSettings,
    },
    headers: {
      'Cache-Control': 'public, max-age=10, s-maxage=60, stale-while-revalidate=240',
    }
  };
}
